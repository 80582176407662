<template>
    <div class="messageModel" v-show="messageShow">
        <div class="connectBox connectBoxW" @click="closemessage">
            <div class="message0" @click.stop>
                <h1>
                    <p></p>
                    <i class="el-icon-close" @click="closemessage"></i>
                </h1>
                <div class="info">
                    <img src="../assets/Header/success.png" alt="">
                    <p class="messageType">SUCCESS</p>
                    <p v-if="starAvailable != 0" class="Available">{{ starAvailable }} {{ coin.star.name }} Available</p>
                    <p v-if="xstarAvailable != 0" class="Available">{{ xstarAvailable }} {{ coin.xstar.name }} Available</p>
                    <p v-if="oneAvailable != 0" class="Available">{{ oneAvailable }} {{ coin.one.name }} Available</p>
                    <p v-if="twoAvailable != 0" class="Available">{{ twoAvailable }} {{ coin.two.name }} Available</p>
                    <p v-if="thrAvailable != 0" class="Available">{{ thrAvailable }} {{ coin.thr.name }} Available</p>
                    <a :href="'https://artio.beratrail.io/tx/'+resHash.hash" target="_block">Transaction Submitted View on explorer</a>
                </div>
                <div class="btns">
                    <div v-if="starAvailable != 0" @click="addToken(coin.star)">
                        <img src="../assets/Header/metamask.png" alt="">
                        <p>Add to {{ coin.star.name }} MetaMask</p>
                    </div>
                    <div v-if="xstarAvailable != 0" @click="addToken(coin.xstar)">
                        <img src="../assets/Header/metamask.png" alt="">
                        <p>Add to {{ coin.xstar.name }} MetaMask</p>
                    </div>
                    <div v-if="oneAvailable != 0" @click="addToken(coin.one)">
                        <img src="../assets/Header/metamask.png" alt="">
                        <p>Add to {{ coin.one.name }} MetaMask</p>
                    </div>
                    <div v-if="twoAvailable != 0" @click="addToken(coin.two)">
                        <img src="../assets/Header/metamask.png" alt="">
                        <p>Add to {{ coin.two.name }} MetaMask</p>
                    </div>
                    <div v-if="thrAvailable != 0" @click="addToken(coin.thr)">
                        <img src="../assets/Header/metamask.png" alt="">
                        <p>Add to {{ coin.thr.name }} MetaMask</p>
                    </div>
                    <div class="messagebtn" @click="closemessage">OK</div>
                </div>
                <p class="tipGas">
                    <a :href="'https://artio.beratrail.io/tx/'+resHash.hash" target="_blank" rel="noopener noreferrer"> Estimated Gas Refund : {{ resHash.refundRatio }}% (${{ (resHash.refundGasNum*gasPrice).toFixed(2) }}) ></a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getTokenSymbol
} from '../utils/space.js'
export default {
    name: "Message",
    data() {
        return {
            gasPrice:2200,
            messageShow:false,
            starAvailable:0,
            xstarAvailable:0,
            oneAvailable:0,
            twoAvailable:0,
            thrAvailable:0,
            coin:{
                star:{name:'',contract:''},
                xstar:{name:'',contract:''},
                one:{name:'',contract:''},
                two:{name:'',contract:''},
                thr:{name:'',contract:''},
            },
            resHash:'',
        }
    },
    created() { },
    mounted() { },
    methods: {
        open(data){
            this.messageShow = data.messageShow
            this.starAvailable = data.starAvailable
            this.xstarAvailable = data.xstarAvailable
            this.oneAvailable = data.oneAvailable
            this.twoAvailable = data.twoAvailable
            this.thrAvailable = data.thrAvailable
            this.coin = data.coin
            this.resHash = data.resHash
        },
        addToken(coinInfo){
            getTokenSymbol(coinInfo.contract).then(res => {
                ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: coinInfo.contract,//'0xb60e8dd61c5d32be8058bb8eb970870f07233155',
                            symbol: res,//'FOO',
                            decimals: coinInfo.decimals,//18,
                            image: coinInfo.icon//'https://foo.io/token-image.svg',
                        }
                    }
                }).then((res) => {
                },error => {
                    //console.log(error)
                }).catch(function(error) {
                    //console.log(error)
                })
            })
        },
        closemessage(){
            //console.log(1)
            this.messageShow = false
        }
    }
}
</script>